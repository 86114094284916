<template>
  <div style="width:100%; height:56px">
    <span v-if="fieldAttrs && fieldAttrs.multiple">
      <ion-label
        :id="triggerID" position="stacked">
        {{ label }}

        <ErrorMessage
          class="error-message-label"
          :name="name" />
      </ion-label>

      <ion-button
        :id="triggerID">
        {{ $t('buttons.add') }}
      </ion-button>
    </span>
<!-- 
<ion-item v-else :id="triggerID">
        <ion-label position="floating" >
          {{ label }}
        </ion-label>
        <ion-input
          readonly type="text"
          :id="triggerID" position="stacked">

          <ErrorMessage
            class="error-message-label"
            :name="name" />
        </ion-input>
    </ion-item> -->

    <div :style="'height: 100%; width: 100%; padding-left:15px; font-size:'+(selected?'16px; ':'21px;padding-top:12px;')" :id="triggerID" v-else>
      
    <ion-label type="md"
      position="stacked">
      {{ label }}

      <ErrorMessage
        class="error-message-label"
        :name="name" />
    </ion-label>

     <ion-chip v-show="selected" :disabled="fieldAttrs.disabled" >
      {{ selected }}
      <ion-button fill="clear" @click="clearSelection" >
        <ion-icon :icon="clearIcon" />
      </ion-button>
    </ion-chip>

    </div>
    <ion-popover v-if="!fieldAttrs.disabled" :trigger="triggerID" @didPresent="didPresent">
      <ion-list>
        <ion-item>
          <ion-input ref="searchinput"
            autofocus="true"
            v-model="placeholder"
            @keyup="searchItems"
            type="search"
            placeholder="Search..."
            v-bind="fieldAttrs"
          />
        </ion-item>

        <ion-progress-bar v-if="loading" type="indeterminate" />

        <ion-item
          v-show="!loading"
          v-for="(item) in items"
          :key="item.value">
          <ion-button style="width:100%; height:100%"
            @click="selectItem(item)"
            fill="clear">
            {{ item.text }}
          </ion-button>
        </ion-item>
      </ion-list>
    </ion-popover>

   
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { closeOutline as clearIcon } from 'ionicons/icons';
import {
  popoverController, IonLabel, IonButton, IonChip, IonIcon,
  IonList, IonPopover, IonInput, IonProgressBar, IonItem,
} from '@ionic/vue';
import moment from 'moment';
import uniqid from 'uniqid';
import { ErrorMessage } from 'vee-validate';

export default {
  components: {
    ErrorMessage,
    IonLabel,
    IonButton,
    IonChip,
    IonIcon,
    IonList,
    IonItem,
    IonPopover,
    IonInput,
    IonProgressBar,
  },
  watch: {
    items(/*v*/){},
    'fieldAttrs.key': {
      handler() {
        this.fetchItems();
      },
    },
  },
  data() {
    return {
      loading: false,
      items: [],
      triggerID: uniqid(),
      showItems: false,
      placeholder: '',
      clearIcon,
    };
  },
  emits: ['changeField'],
  setup() {
    const store = useStore();

    return {
      store,
    };
  },
  props: {
    fieldAttrs: {
      type: Object,
      required: true,
    },
    values: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
  computed: {
    value: {
      get() {
        return this.values[this.name];
      },
      set(value) {
        this.change(value);
        if (typeof this.fieldAttrs.change === 'function') {
          this.fieldAttrs.change(value);
        }
        return value;
      },
    },
    action() {
      return this.fieldAttrs?.action;
    },
    actionParams() {
      return this.fieldAttrs?.actionParams || {};
    },
    selectedItem() {
      if (this.fieldAttrs?.multiple) {
        return this.values[this.name] && this.items ? this.items.filter(({ value }) => value === this.values[this.name]) : null;
      }

      return this.values[this.name] && this.items ? this.items.find(({ value }) => value === this.values[this.name]) : null;
    },
    selected() {
      if (this.fieldAttrs?.multiple) {
        return this.values[this.name] && this.items ? this.items.filter(({ value }) => value === this.values[this.name])?.text : '';
      }

      return this.values[this.name] && this.items ? this.items.find(({ value }) => value === this.values[this.name])?.text : '';
    },
  },
  methods: {
    didPresent(){
      this.$nextTick(function () {this.$refs.searchinput.$el.setFocus()});
    },
    change(value) {
      const options = {
        items: this.items,
      };

      this.$emit('changeField', value, options);
    },
    clearSelection() {
      if (this.fieldAttrs?.multiple) {
        // TODO clear item in array
      } else {
        this.$emit('changeField', undefined);
      }
    },
    fetchItems(e) {
      const query = e?.target?.value;

      if (this.action) {
        this.loading = true;

        return this.store.dispatch(this.action, this.actionParams).then((items) => {
          this.items = items
            .filter(({ text }) => !query || text.toLowerCase().includes(query.toLowerCase()) )
            .sort(({ PickupTime: a }, { PickupTime: b }) => moment(a, 'HH:mm:ss').diff(moment(b, 'HH:mm:ss')));

          if (typeof this.fieldAttrs.filterItems === 'function') {
            this.items = this.items.filter(this.fieldAttrs.filterItems);
          }

          this.loading = false;
        }).catch(() => {
          this.loading = false;
        });
      }
      else{ 
       return new Promise(()=>{
            this.items = this.fieldAttrs.values
            ?.filter(({ text }) => !query || text.toLowerCase().includes(query.toLowerCase())) || []
       // this.items = this.fieldAttrs.values//.slice(0, 10);

        this.showItems = true;
             // return new Promise
       })
      
      }

      //return Promise.reject();
    },
    searchItems(e) {
      clearTimeout(this.timer);

      this.timer = setTimeout(() => {
        this.fetchItems(e).then(() => {
          this.showItems = true;
        });
      }, 500);
    },
    selectItem(item) {
      this.showItems = false;
      popoverController.dismiss();
      if (typeof this.fieldAttrs.change === 'function') {
        this.fieldAttrs.change(item);
      }
      this.change(item.value);
    },
  },
  mounted() {
    this.fetchItems().then(() => {
      if (typeof this.fieldAttrs.change === 'function') {
        this.fieldAttrs.change(this.selectedItem);
      }
    });
  },
};
</script>
<style scoped>
ion-popover, popover-content{
    --max-height: 40vh;
    /* --width:100% */
}
/* ion-label{
  width:100%
} */
/* .autocomplete > div{
  padding-left: 0px;
} 
.autocomplete div.item-inner{
  padding-left: 15px;
} 
.autocomplete div.input-wrapper{
    font-size: 22px;
} */
/* .item-native {
    width: 100%;
    padding-left: 0;
    height: 100%;
    margin: 0;
    --padding-start: 0;
} */
</style>