import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'

import { IonicVue, IonContent, IonPage } from '@ionic/vue';
import { createI18n } from 'vue-i18n';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

const en = require('./locales/en.json')

const i18n = createI18n({
  globalInjection: true,  
  legacy: false,
  locale: 'en',
  messages: {
    en,
    // ja: {
    //     language: '言語',
    //     hello: 'こんにちは、世界！'
    // }
  }
})


const app = createApp(App)
  .use(store)
  .use(IonicVue)
  .use(router)
  .use(i18n);

app.component('ion-content', IonContent);
app.component('ion-page', IonPage);
  
router.isReady().then(() => {
  app.mount('#app');
});