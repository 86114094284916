<template>
    <div>
        <ion-card>
            <ion-card-header>
                <!-- <ion-card-subtitle><ion-text>{{$t('salespeople')}}</ion-text></ion-card-subtitle> -->
                <ion-card-title>
                    <ion-grid>
                        <ion-row>
                            <ion-col v-if="PRIV_CASH_MNG" size-sm="2" size-xs="12">
                                <ion-button
                                    @click="setModalOpen(true, 'add')" color="success">
                                    <ion-icon slot="icon-only" :icon="addCircle"></ion-icon>
                                </ion-button>
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                </ion-card-title>
            </ion-card-header>

            <ion-card-content>
                <app-table size="small"  
                    :tableAttrs="{style:'margin:0 auto'}"
                    :headers="tableHeader"
                    :items="tableData"
                    :trAttrs="{class:'tr-hover'}"
                    :filter="{date:'', }"
                    :itemsFoot="tableFooter"
                    blockSort 
                    :fields="{}"
                    @rowClick="(row)=>setModalOpen(true, 'edit', row)"
                    noPaginate
                >
                    <template v-slot:rowControls-end="rowCtrl" v-if="PRIV_CASH_MNG">
                        <ion-button size="default" color="danger" @click.stop="removeRow(rowCtrl.data)" fill="clear">
                            <ion-icon v-if="!rowCtrl.data.row.loading" :icon="trash"/>
                            <ion-spinner v-else name="dots"></ion-spinner>
                        </ion-button>
                    </template>
                </app-table>
            </ion-card-content>
        </ion-card>
        <ion-modal v-if="PRIV_CASH_MNG"
            :is-open="isModalOpen"
            @didDismiss="setModalOpen(false)"
        > 
            <ion-content>
                <ion-card>
                    <ion-card-header>
                        <!-- <ion-card-subtitle><ion-text>{{$t('salespeople')}}</ion-text></ion-card-subtitle> -->
                        <ion-card-title>
                            {{ModalTitle}}
                        </ion-card-title>
                    </ion-card-header>
                    <ion-card-content>
                        <AppForm
                            :default-values="ModalForm"
                            :fields="ModalFields"
                            @change="formChange"
                        >
                           <template #controls="{validate, values}">
                                <ion-button
                                    :disabled="saving"
                                    @click="submit(validate, values)">
                                    <ion-spinner class="ion-margin-end" v-if="saving" />
                                    {{t('Save')}}
                                </ion-button>
                            </template>
                        </AppForm>
                    </ion-card-content>
                </ion-card>
            </ion-content>
        </ion-modal>
    </div>
</template>

<script>
import { defineComponent, ref, watch , onMounted, computed
// watchEffect 
} from 'vue';
import { IonCard, 
//IonCardHeader, IonCardSubtitle, 
IonCardContent, //IonCardTitle, IonText
IonCardHeader, //IonCardSubtitle, 
IonCardTitle, //IonText,
IonButton, 
IonIcon,
        IonGrid, IonRow, IonCol,
        IonModal, IonSpinner
 } from '@ionic/vue';
//import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex'
import { addCircle, trash } from 'ionicons/icons';
import moment from 'moment';

import AppTable from './AppTable/index.vue';
import AppForm from '../components/AppForm';
//import FormField from './AppForm/components/FormField.vue';


export default defineComponent({
    name: 'CashMovements',
    components: {
        IonCard, 
        // IonCardHeader, 
        // IonCardSubtitle, 
        IonCardContent,
        IonGrid, IonRow, IonCol,
        IonCardHeader, //IonCardSubtitle, 
        IonCardTitle, //IonText,
        IonButton, 
        IonIcon,
        IonModal,
        AppTable,
        AppForm, IonSpinner
        // FormField, IonText, IonCardTitle
    },
    setup(){
        const { t } = useI18n()
        const store = useStore()

        const PRIV_CASH_MNG = store.getters.PRIV_CASH_MNG

        const appDate = computed(() => store.getters['appDate'] )
        //const appDate = ref();
        const tableHeader = ref([
            {value:'CashIn', class:'ion-text-center'},
            {value:'CashOut', class:'ion-text-center'},
            {value:'Name', class:'ion-text-center'},
            {value:'BankIn', class:'ion-text-center'},
            {value:'BankOut', class:'ion-text-center'},
        ]);

        const tableData = ref([])
        const tableFooter = computed(()=>{
            const Foot = {
                CashIn:0,
                CashOut:0,
                Name : t('Total'),
                BankIn:0,
                BankOut:0
            }
            tableData.value.forEach(el => { 
                Foot.CashIn += el.CashIn
                Foot.CashOut += el.CashOut
                Foot.BankIn += el.BankIn
                Foot.BankOut += el.BankOut
            });

            return [Foot]
        })

        const tableLoading = ref(false);

        const isModalOpen  = ref(false);
        const ModalTitle = ref('');
        const ModalFields = ref({
            REC_ID  : { type:'hidden', hidden:true },
            DESCRIPTION: { type:'text', required: true, sizeCol:"12" },
            CASH_IN  : { type: 'number',  sizeCol:'6' },
            CASH_OUT: { type: 'number',  sizeCol:'6' },
            BANK_IN  : { type: 'number',  sizeCol:'6' },
            BANK_OUT  : { type: 'number',  sizeCol:'6' },
            DATE: { type:'hidden', hidden:true, required: true  }
        })
        const ModalForm = ref({
            REC_ID:'',
            DESCRIPTION: '',
            CASH_IN: '0',
            CASH_OUT: '0',
            BANK_IN: '0',
            BANK_OUT: '0',
            DATE: '',
        })
        const saving = ref(false);

        watch(appDate, ()=>{ console.log('watch', appDate.value);
            getList()
        })

        function setTableData(data){
            tableData.value = data?.data || []
        }

        async function getList(){
            tableLoading.value = true
            const data = await store.dispatch('getCashMovements', {
                DATE:appDate.value,//'2019-08-05',
                //LIMIT:20
            }).then((data)=> data)
            .catch(()=>{})
            setTableData(data)
            tableLoading.value = false
        }

        const setModalOpen = async (state, title, form) => { console.log(appDate.value);
            isModalOpen.value = state;
            if(!state){ 
                ModalForm.value = { //...ModalForm.value, ...{
                        REC_ID: '',
                        DESCRIPTION: '',
                        CASH_IN: 0, // '0',
                        CASH_OUT: 0, //'0',
                        BANK_IN: 0, //'0',
                        BANK_OUT: 0, //'0',
                        DATE: appDate.value
                    }
                //}
                return
            }
            ModalTitle.value = t(title)
    
            ModalForm.value = {
                ...{
                    REC_ID: form?.Id || '',
                    DESCRIPTION: form?.Name || '',
                    CASH_IN: form?.CashIn || 0 , //.toString() || '0',
                    CASH_OUT: form?.CashOut || 0 , //.toString() || '0',
                    BANK_IN: form?.BankIn || 0 , //.toString() || '0',
                    BANK_OUT: form?.BankOut || 0 , //.toString() || '0',
                    DATE: form?moment(form.Date).format('YYYY-MM-DD') : appDate.value
                },
            }
        }

        // function formChange({values}){ console.log("aa", values);
        //     //ModalForm.value = values
        // }

        function submit(validate, values) {
            validate().then(({ valid, errors }) => { console.log({ valid, errors });
                if (!valid) {
                    throw new Error(errors);
                }
                saving.value = true;
                // const makeString = {}
                // for(const x in values) makeString[x] = values[x].toString()
                store.dispatch('updateCashMovements', values).then((data)=> {
                    console.log(data);
                    saving.value = false;
                    setModalOpen(false)
                    getList()
                }).catch(() => {
                    saving.value = false;
                    // toastController
                    // .create({
                    //     message: error.message,
                    //     color: 'danger',
                    //     duration: 4000
                    // }).then((toast) => toast.present());
                });
            }).catch((e) => {console.log(e);})
        }

        async function removeRow({index, row}){ console.log(row);
            tableData.value[index].loading = true
            await store.dispatch('removeCashMovements', {
                CASH:row.Id
            }).then(() => {
                //getList()
                tableData.value = tableData.value.filter(r => { return r.Id != row.Id})
            }).catch(()=>{})//.
            //tableData.value[index].loading = false
        }

        onMounted(getList)
       
        return {
            t, //router,
            appDate, 
            tableHeader, 
            tableData, tableLoading, 
            saving, addCircle,
            isModalOpen, setModalOpen, ModalTitle,
            ModalFields, ModalForm, //formChange,
            submit, removeRow, trash, tableFooter,
            PRIV_CASH_MNG
        }
    }

})
</script>

<style>
ion-card{
    overflow: auto;
    max-height: 90vh;
}
ion-modal{
    --height: 480px
}
</style>