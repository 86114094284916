<template>
  <span>
    <div>
      <span
        v-if="controls && controls.many && Object.keys(controls.many).length"
      >
        <ion-button
          v-for="({path, newTab, appendToParams}, key) in controls.many"
          :key="key"
          :to="parsePathMany(path, route.query, appendToParams)"
          :target="newTab && '_blank'"
          class="ion-margin"
        >
          {{ $t('buttons.' + key) }}
        </ion-button>
      </span>
      <ion-button
        v-if="filters && Object.keys(filters).length"
        fill="clear"
        @click="() => showFilters = !showFilters"
      >
        <ion-icon :icon="filterIcon" />
      </ion-button>
    </div>
    <AppForm
      v-show="showFilters"
      v-if="filters && Object.keys(filters).length"
      :fields="filters"
      :default-values="filterValues"
      auto-submit
      @change="change"
    />

    <table v-bind="tableAttrs">
      <thead>
        <th v-show="!hideBlankColumns">
          <slot name="head-start"></slot>
        </th>

        <th v-for="(header, key) in headers" :key="key" v-bind="header.thAttrs" v-show="!header.hidden">
          <ion-button :size="size" v-if="header.value && !blockSort"
            fill="clear"
            @click="sortColumn(key)"
            @mouseenter="showSort[key] = true"
            @mouseleave="showSort[key] = false">
            {{ translate ? $t('fields.' + header.value) : header.value }}

            <ion-icon
              v-show="showSort[key] || pagination.sortBy === headers[key].value"
              :color="pagination.sortBy !== headers[key].value && 'secondary'"
              :icon="sortDesc ? arrowDownIcon : arrowUpIcon"/>
          </ion-button>
          <ion-text color="primary" v-else-if="header.value && blockSort" class="ion-padding-start">
            {{ translate ? $t('fields.' + header.value) : header.value }}
          </ion-text>
        </th>

        <th v-show="!hideBlankColumns">
          <slot name="head-end"></slot>
        </th>
      </thead>

      <tbody>
        <tr
          v-for="(row, index) in items"
          :key="row.id"
          v-bind="row.trAttrs || trAttrs" @click="$emit('rowClick', row)">
          <td v-show="!hideBlankColumns" class="no-header">
            <slot name="rowControls-start" v-bind:data="{row, index}"></slot>
          </td>
          <!-- <td class="no-header">
            <div
             v-for="({
                path,
                icon,
                modal,
                fieldRequirements,
                userRequirements,
                newTab,
                action,
                confirm,
                appendToParams,
              }, key) in rowControls"
              :key="key"
            >
            </div>
          </td> -->

          <td v-for="(column, key) in headers" :key="key" :class="column.class" v-show="!column.hidden" v-bind="row.tdAttrs && row.tdAttrs[column.value]">
            <!-- <AppForm v-if="column.type==='form'"
              :fields="row[column.value].fields"
              :method="row[column.value].method"
              :action="row[column.value].action"
              :default-values="row[column.value].values"
              :formAttrs="row[column.value].formAttrs"
              auto-submit
            /> v-else -->
            <TableField v-if="typeof row[column.value] != 'undefined'"
              :key="column.value"
              :type="column.type"
              :field-attrs="column"
              :value="row[column.value]"
            />
          </td>

          <td v-show="!hideBlankColumns" class="no-header">
            <ion-button :size="size" 
              v-for="({
                path,
                icon,
                modal,
                fieldRequirements,
                userRequirements,
                newTab,
                click,
                action,
                confirm,
                appendToParams,
              }, key) in rowControls"
              :key="key"
                v-show="canShowRowControl(fieldRequirements, userRequirements, row)"
                :target="newTab && '_blank'"
                :href="parsePath(path, row, route.query, appendToParams)"
                color="primary"
                icon
                v-bind="attrs"
                class="ion-margin"
                @click="modal ? prepareModal(modal, row)
                  : clickedRowControl(click, action, confirm, row, index)"
              >
                <ion-icon v-if="icon" :name="icon"/>

                {{ $t('buttons.' + key) }}
            </ion-button>
            <slot name="rowControls-end"  v-bind:data="{row, index}"></slot>
          </td>
        </tr>
      </tbody>

      <tfoot>
        <tr v-for="(row) in itemsFoot" :key="row.id" v-bind="row.trAttrs || trAttrs" @click="$emit('rowClick', row)">
          <th v-show="!hideBlankColumns" class="no-header">
            <!-- (row, index) in items <slot name="rowControls-start" v-bind:data="{row, index}"></slot> -->
          </th>
            <th v-for="(column, key) in headers" :key="key" :class="column.class" v-show="!column.hidden">
              <TableField v-if="typeof row[column.value] != 'undefined'"
                :key="column.value"
                :type="column.type"
                :field-attrs="column"
                :value="row[column.value]"
              />
            </th>
          <th v-show="!hideBlankColumns">
          <!-- <slot name="head-end"></slot> -->
          </th>
        </tr>

        <tr v-if="totals">
          <th v-show="!hideBlankColumns" />

          <th v-for="(header, key) in headers" :key="key" class="ion-padding">
            <TableField
              :key="header.value"
              :type="header.type"
              :field-attrs="header"
              :value="totals[header.value]"
            />
          </th>
        </tr>
      </tfoot>
    </table>
    <div>
      <ion-row class="ion-justify-content-end" v-if="!noPaginate">
          <ion-col v-if="!noRowPerPage">
            <ion-item>
              <ion-label>
                {{$t('titles.rows-per-page')}}
              </ion-label>
              <ion-select
                @change="changeLimit(limit)"
                :value="pagination.itemsPerPage">
                <ion-select-option v-for="limit in itemsPerPageOptions" :value="limit" :key="limit">
                  {{limit}}
                </ion-select-option>
              </ion-select>
            </ion-item>
          </ion-col>
          <ion-col>
            <ion-button :size="size" 
              :disabled="pagination.page <= 1"
              @click="paginate(pagination.page - 1)"
              fill="clear">
              <ion-icon :icon="prevIcon"/>
            </ion-button>

            <ion-button :size="size" 
              :disabled="pagination.page >= pages"
              @click="paginate(pagination.page + 1)"
              fill="clear">
              <ion-icon :icon="nextIcon"/>
            </ion-button>
          </ion-col>
      </ion-row>
    </div>
    <ion-action-sheet
      :isOpen="modals.delete"
      :header="$t('titles.delete')"
      :buttons="modalButtons.delete"
      @didDismiss="modals.delete = false"/>

    <ion-action-sheet
      :isOpen="modals.confirm"
      :header="$t('titles.confirm')"
      :buttons="modalButtons.confirm"
      @didDismiss="modals.confirm = false"/>
  </span>
</template>

<script>
import AppForm from '@/components/AppForm';
import { mapState } from 'vuex';
import {
  IonButton,
  IonIcon,
  IonCol,
  IonRow,
  IonActionSheet,
  toastController,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonItem,
  IonText
} from '@ionic/vue';
import {
  arrowDownOutline as arrowDownIcon,
  arrowUpOutline as arrowUpIcon,
  filterOutline as filterIcon,
  chevronForwardOutline as nextIcon,
  chevronBackOutline as prevIcon,
  trashBinOutline as deleteIcon,
  checkmarkOutline as checkmarkIcon,
} from "ionicons/icons";

import TableField from './components/TableField.vue';
import { useRouter, useRoute } from 'vue-router';

export default {
  components: {
    TableField,
    AppForm,
    IonButton,
    IonIcon,
    IonCol,
    IonRow,
    IonActionSheet,
    IonLabel,
    IonSelect,
    IonSelectOption,
    IonItem,
    IonText
  },
  props: {
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    totals: {
      type: Array,
      default: null,
    },
    itemsFoot: {
      type: Array,
      //required: true,
    },
    model: {
      type: String,
      //required: true,
    },
    fields: {
      type: Object,
      default() {
        return {};
      },
    },
    filters: {
      type: Object,
      default() {
        return {};
      },
    },
    controls: {
      type: Object,
      default() {
        return {
          many: {},
          rowControls: {},
        };
      },
    },
    itemsPerPage: {
      type: Number,
      default: 20,
    },
    totalItems: {
      type: Number,
      default: null,
    },
    size:{
      type:String,
      default:'default'
    },
    blockSort:{
      type:Boolean,
      default:false
    },
    translate: {
      type: Boolean,
      default: true,
    },
    noPaginate:{
      type:Boolean,
      default:false
    },
    noRowPerPage:{
      type:Boolean,
      default:false
    },
    trAttrs: {
      type: Object,
      default() {
        return {};
      },
    },
    tableAttrs:{
      type: Object,
      default() {
        return {};
      },
    },
    hideBlankColumns:{
      type:Boolean,
      default:false
    },
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    return { router, route };
  },
  data() {
    return {
      itemsPerPageOptions: [20, 50, 100, 500],

      modals: {
        delete: false,
        confirm: false,
      },

      modalButtons: {
        delete: [{
          text: this.$t('titles.delete'),
          role: 'destructive',
          icon: deleteIcon,
          data: {
            type: 'delete'
          },
          handler: () => {
            this.deleteEntity();
          },
        }],
        confirm: [{
          text: this.$t('titles.confirm'),
          icon: checkmarkIcon,
          handler: () => {
            this.confirmAction();
          },
        }],
      },

      entityValues: {},

      filterValues: {},

      showFilters: false,

      showSort: {},

      arrowDownIcon,
      arrowUpIcon,
      filterIcon,
      nextIcon,
      prevIcon,
      checkmarkIcon,
    };
  },
  computed: {
    parsePathMany() {
      return (path, queryParams, appendToParams) => {
        if (!appendToParams || !queryParams) {
          return path;
        }

        const validQueryParams = [];

        Object.keys(queryParams).forEach((param) => {
          if (queryParams[param]) {
            if (typeof queryParams[param] === 'object' && queryParams[param].length) {
              queryParams[param].forEach((v) => {
                validQueryParams.push([`${param}[]`, v]);
              });
            } else {
              validQueryParams.push([param, queryParams[param]]);
            }
          }
        });

        return `${path}?${new URLSearchParams(validQueryParams)}`;
      }
    },
    parsePath () {
      return (path, row, queryParams, appendToParams) => {
        if (!row) {
          return path;
        }

        let parsedPath = path;
        const occurences = path ? path.match(/:[a-zA-Z.]+/g) : [];

        if (occurences && occurences.length) {
          occurences.forEach((i) => {
            const fieldToReplace = i.replace(':', '').split('.');
            let value = row;

            if (value) {
              fieldToReplace.forEach((field) => {
                value = value && value[field] ? value[field] : `${value}.${field}`;
              });
            }

            parsedPath = parsedPath.replace(i, value);
          });
        }

        if (queryParams && appendToParams) {
          const validQueryParams = [];

          Object.keys(queryParams).forEach((param) => {
            if (queryParams[param]) {
              if (typeof queryParams[param] === 'object' && queryParams[param].length) {
                queryParams[param].forEach((v) => {
                  validQueryParams.push([`${param}[]`, v]);
                });
              } else {
                validQueryParams.push([param, queryParams[param]]);
              }
            }
          });

          return `${parsedPath}?${new URLSearchParams(validQueryParams)}`;
        }

        return parsedPath;
      }
    },
    pagination() {
      return {
        sortBy: this.route.query.sortBy,
        sortType: this.route.query.sortType,
        itemsPerPage: +this.route.query.limit || 20,
        page: +this.route.query.page || 1,
      };
    },
    pages(){
      return Math.ceil(this.totalItems / this.pagination.itemsPerPage);
    },
    sortDesc(){
      return this.route.query.sortType === 'desc';
    },
    ...mapState({
      user: (state) => state?.auth?.user || {},
    }),
    rowControls() {
      return this.controls?.rowControls || {};
    },
  },
  mounted() {
    this.filterValues = { ...this.route.query };
  },
  methods: {
    paginate(page) {
      this.appliedFilters({
        ...this.pagination,
        page,
      });
    },
    changeLimit(limit) {
      this.appliedFilters({
        ...this.pagination,
        limit,
      });
    },
    sortColumn(column) {
      let sortType;
      let sortBy;

      if (this.headers[column].value !== this.pagination.sortBy || typeof this.pagination.sortType === 'undefined') {
        sortType = 'asc';
        sortBy = this.headers[column].value;
      } else if (this.pagination.sortType === 'asc') {
        sortType = 'desc';
        sortBy = this.headers[column].value;
      }
      
      this.appliedFilters({
        sortBy,
        sortType,
      });
    },
    canShowRowControl(fieldRequirements, userRequirements, row) {
      let canShow = true;

      if (typeof fieldRequirements === 'object' && fieldRequirements.length) {
        fieldRequirements.forEach((name) => {
          // Fields that start with '!' must not resolve to true
          if (name.startsWith('!')) {
            const fieldName = name.replace('!', '');
            if (row[fieldName]) {
              canShow = false;
            }
          } else if (!row[name]) {
            canShow = false;
          }
        });
      }

      if (typeof userRequirements === 'object' && userRequirements.length && this.user) {
        userRequirements.forEach((name) => {
          // Fields that start with '!' must not resolve to true
          if (name.startsWith('!')) {
            const fieldName = name.replace('!', '');
            if (this.user[fieldName] !== row[fieldName]) {
              canShow = false;
            }
          } else if (this.user[name] === row[name]) {
            canShow = false;
          }
        });
      }

      return canShow;
    },
    prepareModal(modalToOpen, item) {
      if (modalToOpen) {
        this.entityValues = { ...item };
        this.modals[modalToOpen] = true;
      }
    },
    clickedRowControl(click, action, confirm, item, index) {
      if (typeof click === 'function') {
        click(item, index);
      } else if (confirm) {
        this.entityValues = { action, item };
        this.modals.confirm = true;
      } else if (action) {
        this.$store.dispatch(`${this.model}/${action}`, {
          data: item,
          params: this.route.query,
        });
      }
    },
    closeModals() {
      Object.keys(this.modals).forEach((m) => {
        this.modals[m] = false;
      });
    },
    deleteEntity() {
      if (this.entityValues?.id) {
        const { id } = this.entityValues;
        this.storeLastDelete(id).then(() => {
          this.$store.dispatch(`${this.model}/delete`, id).then(() => {
            this.$emit('appliedFilters', this.filterValues);
            this.closeModals();

            
        
          toastController
            .create({
              message: this.$t('successCodes.deleted-successfully'),
              color: 'success',
              duration: 4000
            }).then((toast) => toast.present());
          });
        });
      }
    },
    confirmAction() {
      const { item, action } = this.entityValues;
      this.loadingModals.confirm = true;
      this.$store.dispatch(`${this.model}/${action}`, { data: item, params: this.route.query }).then(() => {
        this.$emit('appliedFilters', {
          limit: this.route.query.limit,
          sortBy: this.route.query.sortBy,
          sortType: this.route.query.sortType,
          page: this.route.query.page,
        });
        this.loadingModals.confirm = false;
        this.closeModals();
      });
    },
    successCallback() {
      toastController
        .create({
          message: this.$t('successCodes.success'),
          color: 'success',
          duration: 4000
        }).then((toast) => toast.present());
    },
    failureCallback(errorMessage) {
      toastController
        .create({
          message: this.$t(`errorCodes.${errorMessage}`),
          color: 'danger',
          duration: 4000
        }).then((toast) => toast.present());
    },
    storeLastDelete(id) {
      return this.$store.dispatch(`${this.model}/setLastDeleted`, id);
    },
    appliedFilters(values) {
      const query = {  ...this.$route.query, ...this.filterValues, ...values };

      if (query.page > 1 && this.pagination.totalItems
      < ((query.page * this.pagination.itemsPerPage) - this.pagination.itemsPerPage)) {
        query.page = 1;
      }

      this.router.push({
        query,
      });

      this.$emit('appliedFilters', query);
    },
    change({ values }){
      this.appliedFilters(values);
    },
  },
};
</script>

<style scoped>
  table {
    /* width: 100%; */
    text-align: left;
  }

  thead th {
    border-top: 1px solid black;
    border-bottom: 1px solid black;
  }

  tbody tr {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    /* border-right: 1px solid rgba(0, 0, 0, 0.1); */
  }
  
  tfoot tr{
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }

  td.no-header {
    text-align: right;
  }
  tr.tr-hover:hover{
    background: var(--lt-color-background-default)
    /* var(--ion-color-step-250) */
  }

  td, th{
    padding: 0 4px 0 2px;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }

/*   
.no-width{
    width:50px
} */

.no-width ion-text{
  padding:0px
}
  /* ion-text.ion-padding-start,
  ion-text.ion-padding-end{
    padding: 8px;
  } */
</style>
