import { createRouter, createWebHistory } from '@ionic/vue-router';
import { gridSharp, analyticsOutline, statsChartOutline, ticketOutline, peopleOutline,/*readerSharp,*/ mapSharp, bookSharp, train, cash, timer, golf, expand, documentTextOutline } from 'ionicons/icons';
import store from '../store';

//import { RouteRecordRaw } from 'vue-router';
import menu  from '@/components/CashMovements.vue'
//const Booking = () => import ('@/views/BookingExpress.vue')
const meta = {menuHidden:true}; // hide in menu
const addFab = true;
const addToolbar = true;
const print = true

const routes = [
  {
    path: '/',
    redirect: '/generaloverview',
    component: () => import ('@/layout/SideMenuLayout.vue'), // layout
    meta,
    children:[
      {
        path: '/generaloverview',
        name: 'GeneralOverview',// @
        components: {
          menu : menu,
          default:() => import ('@/views/GeneralOverview.vue'),
        },
        meta:{ icon:gridSharp, addFab, addToolbar, fnBackShow:({query})=>{
          return query?.book || false
        }},
      },
      {
        path: '/pickup/assignment',
        name: 'PickupAssignment',
        component: () => import ('@/views/PickupAssignment.vue'),
        meta: {
          privAccess:store.getters.PRIV_DRIVER_ASSIGNMENT,
          icon: train, addFab, addToolbar
        }
      },
      {
        path: '/pickup/extended',//
        name: 'PickupExtended',// @
        component: () => import ('../views/PickupExtendedPage.vue'),
        meta: { privAccess:store.getters.PRIV_PCK_LIST_VIEW, icon:expand, backShow:true, print },
      },
      {
        path: '/excursion/more',///:excursion/:exc/:type/:date
        name: 'ExcursionMore',// @
        component: () => import ('../views/ExcursionMorePage.vue'),
        meta: { privAccess:store.getters.PRIV_MORE_VIEW, /*...meta,*/ backShow:true, print, icon:statsChartOutline },
      },
      {
        path: '/excursion/more/analysis',///:excursion/:date
        name: 'ExcursionMoreAnalysis',// @
        component: () => import ('../views/ExcursionMoreAnalysisPage.vue'),
        meta: { privAccess:store.getters.PRIV_MORE_VIEW, /*...meta,*/ backShow:true, print, icon:analyticsOutline },
      },
      {
        path: '/cash/movements',
        name: 'CashMovements',// @
        component: () => import ('@/views/CashMovementsPage.vue'),
        meta: {  privAccess:store.getters.PRIV_CASH_VIEW, icon: cash },
      },
      {
        path: '/cash-report',//
        name: 'Cash Report',// @
        component: () => import ('../views/CashReport.vue'),
        meta: { privAccess:store.getters.PRIV_CASH_VIEW, icon:cash, backShow:true, print },
      },
      {
        path: '/salespeople/pickup',
        name: 'SalespeopleExcursionPickup',
        component: () => import ('@/views/SalespeopleExcursionPickupPage.vue'),
        meta: { privAccess:store.getters.PRIV_SLSPEOPLE_PCK_VIEW, icon: golf },
      },
      {
        path: '/salespeople/excursion/price',
        name: 'SalespeopleExcursionPrice',
        component: () => import ('@/views/SalespeopleExcursionPricePage.vue'),
        meta: { privAccess:store.getters.PRIV_SLSPEOPLE_EXC_PRICE_VIEW, icon: documentTextOutline },
      },
      {
        path: '/pickup/exception/time',
        name: 'PickupTimeExceptions',// @
        component: () => import ('@/views/PickupTimeExceptionsPage.vue'),
        meta: { privAccess:store.getters.PRIV_PCK_TIME_EXC_VIEW, icon: timer },
      },
      {
        path: '/history',//
        name: 'History',// @
        component: () => import ('../views/HistoryAdmin.vue'),
        meta: { privAccess:store.getters.PRIV_HISTORY_VIEW, icon:bookSharp, backShow:true },
      },
      {
        path: '/user-history',//
        name: 'UserHistory',// @
        component: () => import ('../views/HistoryUsers.vue'),
        meta: { privAccess:store.getters.PRIV_USER_VIEW, icon:peopleOutline, backShow:true },
      },
      {
        path: '/advanced-tickets',//
        name: 'Advanced Tickets',// @
        component: () => import ('../views/AdvancedTickets.vue'),
        meta: { privAccess:store.getters.PRIV_VIEW_TICKET, icon:ticketOutline, backShow:true, print },
      },
      {
        path: '/driver-guide-activity',//
        name: 'Driver / Guide Activity',// @
        component: () => import ('../views/DriverGuideActivity.vue'),
        meta: {
          privAccess:store.getters.PRIV_DRIVER_ACTIVITY_VIEW || store.getters.PRIV_GUIDE_ACTIVITY_VIEW, 
          icon:cash, backShow:true 
        },
      },
    ]
  },
  {
    path: '/book',
    name: 'Book',
    component: () => import('@/layout/BlankLayoutX.vue'),
    //redirect: '/book',
    meta,
    children: [
      {
        name: 'BookSaleperson',
        path: ':salesperson/:pickup/:tourop/:name/:touropcode',
        component: () => import('@/views/BookPage.vue'),
        meta:{ privAccess:store.getters.PRIV_BOOK_ADD, ...meta }
      },
      {
        name: 'EditReservation',
        meta:{ privAccess:store.getters.PRIV_BOOK_VIEW, ...meta },
        path: '/book/:tickettype/:ticketnumber/edit',
        component: () => import('@/views/EditBookPage.vue'),
      },
    ],
  },
  // {
  //   path: '/extended',
  //   name: 'ExtendedPickup',// @
  //   component: () => import ('../views/ExtendedPickup.vue'),
  //   meta: { icon: bookSharp, ...meta },
  // },
  {
    path: '/map',
    name: 'Map',// @
    component: () => import ('@/views/MapTracking.vue'),
    meta: {
      privAccess:store.getters.PRIV_MAP_VIEW,
      icon: mapSharp, addToolbar,
      'windowOpen':'map'
    },
  },
  {
    path: '/login',
    name: 'Login',
    component:  () => import ('@/views/LoginPage.vue'),
    meta
  },
  // 404 - ErrorHandling
  { path: '/:pathMatch(.*)*', meta, name: 'NotFound', component:() => import ('@/views/ErrorHandling/NotFound.vue') },
  {
    // will match everything
    path: '/:catchAll(.*)',
    component: () => import ('@/views/ErrorHandling/NotFound.vue'),
    meta
  },
  { // set: this.$router.push({name:'404Resource', params: {resource: 'event' }})
    path: '/404/:resource',
    name: '404Resource',
    component: () => import ('@/views/ErrorHandling/NotFound.vue'),
    props: true,
    meta
  },
  {
    path: '/network-error',
    name: 'NetworkError',
    component: () => import ('@/views/ErrorHandling/NetworkError.vue'),
    meta
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.name === 'Login') {
    next()
  } else {
    const isLoggedIn = store.getters.isLoggedIn;
    
    if (isLoggedIn) { console.log(' to.meta.privAccess ',  to.meta.privAccess);
      if(typeof to.meta.privAccess == 'undefined' || to.meta.privAccess) // == '1')
      next()
      else next({name:'GeneralOverview'})
    } else {
      next({ name: 'Login' })
    }
  }
})

export default router