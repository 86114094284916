<template>
  <ion-chip
    v-if="type === 'status' && value"
    :color="getStatusColor(value)"
  >
    {{ $t('status.' + value) }}
  </ion-chip>
  <ion-chip
    v-else-if="type === 'type' && value"
    :color="getTypeColor(value)"
  >
    {{ $t('types.' + value) }}
  </ion-chip>
  <span
    v-else-if="type === 'multiple' && value"
  >
    <ion-chip
      v-for="option in value"
      :key="option"
      class="mr-2"
    >
      {{ option }}
    </ion-chip>
  </span>
  <span v-else-if="type === 'date'">
    {{ parseDate(value, moment, 'DD-MM-YYYY') }}
  </span>
  <span v-else-if="type === 'datetime'">
    {{ parseDate(value, moment, 'DD-MM-YYYY HH:mm') }}
  </span>
  <span v-else-if="type === 'image'">
    <a
      :href="value"
    >
      <img
        :height="80"
        :src="value"
      >
    </a>
  </span>
  <span v-else-if="type === 'file'">
    <ion-button
      v-if="value"
      fill="clear"
      :href="value"
    >
      <ion-icon :icon="downloadIcon"/>
    </ion-button>
  </span>
  <span v-else-if="type === 'password'">
    <ion-icon v-for="i in 10" :key="i" class="body-2" :icon="passwordIcon"/>
  </span>
  <span v-else-if="type === 'checkbox'">
    <ion-icon
      v-if="value"
      color="success"
      :icon="checkmarkIcon"
    />
    <ion-icon
      v-else
      color="error"
      :icon="closeIcon"
    />
  </span>
  <span v-else-if="type === 'autocomplete'">
    {{ value && value[fieldAttrs.itemText || 'name'] }}
  </span>
  <span v-else-if="type === 'amount'">
    {{ parseAmount(value) }}
  </span>
  <span v-else-if="type === 'textarea'">
    <ion-button fill="clear" @click="showMore = !showMore">
      <ion-icon :icon="commentIcon"/>
    </ion-button>

    <div
      v-if="fieldAttrs.html"
      v-show="showMore"
      v-html="value"
    />
    <div
      v-else
      v-show="showMore"
    >
      {{ value }}
    </div>
  </span>
  <span v-else-if="type === 'toggle'">
    <!-- v-model="value" -->
    <ion-toggle ></ion-toggle>
  </span>
  <span v-else>
    {{ value }}
  </span>
</template>

<script>
import { IonIcon, IonButton, IonChip, IonToggle } from '@ionic/vue';
import {
  chatbubbleEllipsesOutline as commentIcon, radioButtonOnOutline as passwordIcon,
  downloadOutline as downloadIcon, checkmarkOutline as checkmarkIcon, closeOutline as closeIcon,
} from "ionicons/icons";
import moment from 'moment';

export default {
  components: {
    IonIcon,
    IonChip,
    IonButton,
    IonToggle
  },
  props: {
    value: {
      type: [String, Number, Object, Array, Boolean],
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
    fieldAttrs: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data(){
    return {
      showMore: false,
      commentIcon,
      passwordIcon,
      downloadIcon,
      checkmarkIcon,
      closeIcon,
      moment,
    }
  },
  computed: {
    parseAmount() {
      return (value) => {
        if (!value) {
          return `${parseFloat(0).toFixed(2).toString().replace(".", ",")}`;
        }

        return `${parseFloat(value, 10).toFixed(2).toString().replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
      }
    },
    parseDate() {
      return (value, moment, format) => {
        return value ? moment(value).format(format || 'YYYY-MM-DD') : '-';
      }
    }
  },
  methods: {
    getStatusColor(status) {
      switch (status) {
        case 'cancelled':
          return 'error';
        case 'disabled':
          return 'error';
        case 'rejected':
          return 'error';
        case 'active':
          return '--ion-color-success' //;'success';
        case 'confirmed':
          return '--ion-color-success'; //'success',;
        case 'approved':
          return '--ion-color-success'; //'success',;
        case 'published':
          return '--ion-color-success'; //'success',;
        case 'visible':
          return '--ion-color-success'; //'success',;
        case 'positive':
          return '--ion-color-success'; //'success',;
        case 'negative':
          return '--ion-color-danger'; //'danger';
        default:
          return '--ion-color-warning'; //'warning';
      }
    },
    getTypeColor(status) {
      switch (status) {
        case 'success':
          return '--ion-color-success'; //'success',;
        case 'error':
          return '--ion-color-danger'; //'error';
        default:
          return '--ion-color-warning'; //'warning';
      }
    },
  },
};
</script>
