import { io } from 'socket.io-client';
import store from '../store';

class SocketClient {
    constructor() {
        this.socket = io(process.env.VUE_APP_SOCKET_URL, {
            withCredentials: true,
        });

        this.socket.on("connect", () => {
            store.commit('setSocketConnected', true)
        });
        this.socket.on("disconnect", () => { //this.socket.disconnected
            store.commit('setSocketConnected', false)
        });

        this.socket.connect();
    }

    disconnect() {
        return this.socket.disconnect();
    }

    // map
    watchDriverPosition(callback) {
        return this.socket.on('signal-driver-position', callback);
    }

    watchMonitor() { // ask
        return this.socket.emit('watch-monitor');
    }


    informStopSales(data) {
        return this.socket.emit('stop-sales', data);
    }
    informAvailability(data) {// DRIVER: ID, // DIKA MOY
        return this.socket.emit('update-pax', data);
    }


    onUpdatePax(callback) {
        return this.socket.on('update-pax', callback);
    }
    onStopSale(callback) {
        return this.socket.on('stop-sales', callback);
    }





    




    
    watchDelay(callback) {
    return this.socket.on('inform-delay', callback);
    }

    watchDriver(driver) {
    return this.socket.emit('watch-driver', driver);
    }

    watchPickupChange(callback) {
    return this.socket.on('pickup-change', callback);
    }

    removeFromBus(data) {
        return this.socket.emit('toggle-bookNr', data);
    }

    setInBus(data) {
        return this.socket.emit('toggle-bookNr', data);
    }

    signalDriverPosition(data) {
        return this.socket.emit('signal-driver-position', data);
    }

    updatePax(data) {
        return this.socket.emit('update-pax', data);
    }

    // watchDriver(driver) {
    //     return this.socket.emit('watch-driver', driver);
    // }

    // watchDriverPosition(callback) {
    //     return this.socket.on('signal-driver-position', callback);
    // }

    watchBookNr(callback) {
        return this.socket.on('toggle-bookNr', callback);
    }

    watchDriverChanges(callback) {
        return this.socket.on('update-pax', callback);
    }
}

export default new SocketClient();