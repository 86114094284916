<template>
 <ion-grid>
<ion-row>
  <ion-col v-for="(field, name) in fields" 
    v-show="!field.hidden"
    :size="field.sizeCol || 'auto'"
    :key="name" 
  >
  <FormField
    v-show="!field.hidden"
    :key="name"
    :field-attrs="field"
    :fields="fields"
    :name="name"
    :values="values"
    @changeField="change"
  />
  </ion-col>
</ion-row>
 </ion-grid>
</template>

<script>
import FormField from './FormField.vue';
import {
  IonCol, IonGrid, IonRow
} from '@ionic/vue';

export default {
  components: {
    FormField, IonCol, IonGrid, IonRow
  },
  props: {
    fields: {
      type: Object,
      required: true,
      default:()=>({})
    },
    values: {
      type: Object,
      required: true,
    },
  },
  methods: {
    change(name, value){
      this.$emit('changeField', name, value);
    }
  }
};
</script>
