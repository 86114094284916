<template>
<!-- <ion-grid> -->
  <Form
    :ref="formID"
    @submit="submit"
    :validation-schema="schema"
    v-bind="formAttrs" 
  >
    <!-- <div v-bind="formAttrs" > -->
    <slot name="fields">
      <!-- <ion-row> -->
      <FormFields
        @changeField="change"
        :fields="fields"
        :values="values"
      />
      <!-- </ion-row> -->
    </slot>

    <slot
      v-if="!autoSubmit"
      v-bind="{validate, submit, reset, values}"
      name="controls"
    >
      <ion-button
        v-for="(control, key) in controls"
        :key="key"
        v-bind="control"
        :disabled="loadingButton[key]"
        @click="handleControlClick(control, key)"
        class="ion-margin-end"
      >
        <ion-spinner class="ion-margin-end" v-if="loadingButton[key]" />
        {{ control.label || $t('buttons.' + key) }}
      </ion-button>
    </slot>
    <!-- </div> -->
  </Form>
<!-- </ion-grid> -->

</template>

<script>
import uniqid from 'uniqid';
import { Form } from 'vee-validate';
import {
  IonButton, IonSpinner, toastController, //IonGrid, IonRow
} from '@ionic/vue';
import * as yup from 'yup';
import FormFields from './components/FormFields.vue';

export default {
  components: {
    FormFields,
    Form,
    IonButton,
    IonSpinner, //IonGrid, 
    //IonRow
  },
  props: {
    name: {
      type: String,
      default: null,
    },
    fields: {
      type: Object,
      default() {
        return {};
      },
    },
    defaultValues: {
      type: Object,
      default() {
        return {};
      },
    },
    formAttrs: {
      type: Object,
      default() {
        return {};
      },
    }, 
    method: {
      type: String,
      default: 'GET',
    },
    action: {
      type: String,
      default: null,
    },
    successUrl: {
      type: String,
      default: null,
    },
    multipart: {
      type: Boolean,
      default: false,
    },
    autoSubmit: {
      type: Boolean,
      default: false,
    },
    failureUrl: {
      type: String,
      default: null,
    },
    controls: {
      type: Object,
      default() {
        return {
          submit: {
            type: 'submit',
          },
          clear: {
            type: 'clear',
          },
        };
      },
    },
  },
  data() {
    return {
      loadingButton: {},
      validForm: false,
      formID: this.name || uniqid(),
      values: { ...this.defaultValues },
    };
  },
  computed: {
    schema() {
      const rules = {};

      Object.entries(this.fields).forEach(([name, field]) => {
        const { required, disabled, type, pattern } = field;

        switch (type) {
          case 'email':
            rules[name] = yup.string().email(this.$t('errorCodes.email-is-invalid'));
            break;
          case 'number':
            rules[name] = yup.number();
            break;
          default:
            rules[name] = yup.string();
            break;
        }

        if (required && !disabled) {
          rules[name] = rules[name].required(this.$t('errorCodes.field-is-required'));
        }

        if (pattern) {
          rules[name] = rules[name].matches(pattern, this.$t('errorCodes.field-is-invalid'));
        }
      });

      return yup.object(rules);
    },
  },
  methods: {
    handleSuccess(data) {
      this.$emit('success', {
        data, values: this.values, fields: this.fields, controls: this.controls,
      });

      if (this.successUrl) {
        let parsedPath = this.successUrl;
        const occurences = this.successUrl.match(/:[a-zA-Z.]+/g);

        if (occurences && occurences.length) {
          occurences.forEach((i) => {
            const fieldToReplace = i.replace(':', '').split('.');
            let value = data;

            if (value) {
              fieldToReplace.forEach((field) => {
                value = value && value[field] ? value[field] : value;
              });
            }

            parsedPath = parsedPath.replace(i, value);
          });
        }

        this.$router.push({
          path: parsedPath,
          query: this.$route.query,
        });
      }
    },
    handleFailure(error) {
      let errorMessage;

      if (error && error.response && error.response.data && error.response.data.errno) {
        errorMessage = error.response.data.errno;
      } else if (error && error.request) {
        errorMessage = 'check-your-connection';
      } else {
        errorMessage = 'something-went-wrong';
      }

      this.$emit('failure', {
        values: this.values, fields: this.fields, controls: this.controls,
      });

      if (this.failureUrl) {
        this.$router.push({
          path: this.failureUrl,
        });
      }

      if (errorMessage) {
        toastController
          .create({
            message: this.$t(`errorCodes.${errorMessage}`),
            color: 'danger',
            duration: 4000,
          }).then((toast) => toast.present());
      }
    },
    handleControlClick({ type }, key) {
      switch (type) {
        case 'submit':
          this.loadingButton[key] = true;
          this.submit().then(() => {
            this.loadingButton[key] = false;
          }).catch(() => {
            this.loadingButton[key] = false;
          });
          break;
        case 'clear':
          this.reset();
          break;
        default:
      }
    },
    jsonToFormData(formData, data, parentKey) {
      if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach((key) => {
          this.jsonToFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        });
      } else {
        const value = data == null ? '' : data;

        formData.append(parentKey, value);
      }
    },
    validate() {
      return this.$refs[this.formID].validate();
    },
    submit() {
      return this.validate()
      .then(/*({ errors, valid } )*/result => {
          if (!result.valid) {
              let errMsg = ''
              for (let e in result.errors){
                errMsg += this.$t(e)+": "+result.errors[e]+'<br/>'
              }
                toastController
                .create({
                  message: this.$t(errMsg),
                  color: 'danger',
                  duration: 4000,
                }).then((toast) => toast.present());
            throw new Error(result.errors);
          }

          let body = JSON.stringify(this.values);

          if (this.multipart) {
            const formData = new FormData();
            this.jsonToFormData(formData, this.values);
            body = formData;
          }

          this.$emit('submit', {
            values: this.values, fields: this.fields, controls: this.controls,
          });

          try { //console.log(this.action , this.$route.path);
            const url = new URL(this.action || this.$route.path);

            if (this.method === 'GET') {
              url.search = new URLSearchParams(body).toString();
            }

            return fetch(url, {
              method: this.method,
              body: this.method === 'POST' && body,
            }).then(({ data }) => {
              this.handleSuccess(data);
            }).catch(this.handleFailure);
          } catch (e){ console.log(e); 
            return Promise.reject();
          }
        })
        .catch((e,a) => { console.log(e,a);
          throw new Error('Form is not validated!!! ');
        });
    },
    reset() {
      this.values = { ...this.defaultValues };
    },
    change(name, value) {
      if(this.values[name] == value) return
      this.values[name] = value;

      this.$emit('change', { values: this.values, validate: this.validate });
      if (this.autoSubmit) {
        this.submit();
      }
    },
  },
  // watch: {
  //   // whenever question changes, this function will run
  //   defaultValues(nv) { console.log('!!!!!!!',nv);
  //     this.values = { ...nv }
  //   }
  // },
  watch: {
    // whenever question changes, this function will run
    defaultValues: {
      handler(newValue) {
        this.values = {...newValue}

        // Note: `newValue` will be equal to `oldValue` here
        // on nested mutations as long as the object itself
        // hasn't been replaced.
      },
      deep: true
    }
  },
};
</script>
<style scoped>
ion-grid{
  padding:0
}
</style>