class TokenService {
    constructor() {
        this.init();
    }

    init() {
        this.baseURL = process.env.VUE_APP_MULTI_API_IP;
        this.clientId = process.env.VUE_APP_MULTI_API_CLIENT_ID;
        this.clientSecret = process.env.VUE_APP_MULTI_API_CLIENT_SECRET;
        this.grantType = process.env.VUE_APP_MULTI_API_GRANT_TYPE;
        this.scope = process.env.VUE_APP_MULTI_API_SCOPE;
    }

  getBearerToken() {
    const params = {
      client_id: this.clientId,
      client_secret: this.clientSecret,
      grant_type: this.grantType,
      scope: this.scope,
    };

    return fetch(`${this.baseURL}/oauth`, {
      method: 'POST',
      body: new URLSearchParams(params),
    })
      .then(response => response.json())
      .then(({ access_token }) => access_token);
  }
}

export default new TokenService();