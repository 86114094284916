<template>
  <Field
    :name="name"
    :type="fieldAttrs.type"
    v-model="value"
  >
  <!-- <ion-col> -->
    <ion-item :class="classes"
      v-if="fieldAttrs.type === 'select'">
      <ion-label
        position="floating">
        {{ parseLabel() }}

        <ErrorMessage
          class="error-message-label"
          :name="name" />
      </ion-label>

      <ion-select
        v-model="value"
        v-bind="fieldAttrs"
      >
        <ion-select-option
          v-for="(option, key) in fieldAttrs.values"
          :key="key"
          :value="option.value"
        >
          {{ option.text }}
        </ion-select-option>
      </ion-select>
    </ion-item>

    <ion-list
      v-else-if="fieldAttrs.type === 'radio'"
    >
      <ion-list-header v-if="!fieldAttrs.noLabels">
        {{ parseLabel() }}

        <ErrorMessage
          class="error-message-label"
          :name="name" />
      </ion-list-header>
      <ion-radio-group
        v-model="value"
        v-bind="fieldAttrs"
      >
        <ion-item :class="classes"
          v-for="(radio, key) in fieldAttrs.values"
          :key="key"
          v-model="value">
          <ion-label>
            {{ radio.text }}
          </ion-label>
          <ion-radio v-bind="fieldAttrs"
            :value="radio.value" />
        </ion-item>
      </ion-radio-group>
    </ion-list>

    <ion-item :class="classes"  v-else-if="fieldAttrs.type === 'one-checkbox'"
      v-bind="fields[name].itemAttrs"
    >
      <ion-label>
         {{ parseLabel() }}
              <ErrorMessage
          class="error-message-label"
          :name="name" />
        </ion-label>
       <!-- @click.stop="" -->
      <ion-checkbox  slot="end"
        v-model="value"
        v-bind="fieldAttrs"
      />
    </ion-item>
    
    <!-- <ion-item v-else-if="fieldAttrs.type === 'one-checkbox'">-->
      <!-- <ion-label>{{entry.val}}</ion-label> -->
       <!-- <ion-list-header v-if="!fieldAttrs.noLabels">
        {{ parseLabel() }}
        <ErrorMessage
          class="error-message-label"
          :name="name" />
      </ion-list-header>
      <ion-checkbox
        slot="end" v-bind="fieldAttrs"
        :value="value">
      </ion-checkbox>
    </ion-item> -->

    <ion-list
      v-else-if="fieldAttrs.type === 'checkbox'"
      lines="full"
      v-model="value"
      v-bind="fieldAttrs"
      >
      <ion-list-header v-if="!fieldAttrs.noLabels">
        {{ parseLabel() }}

        <ErrorMessage
          class="error-message-label"
          :name="name" />
      </ion-list-header>
      <ion-item :class="classes"
        v-for="(checkbox, key) in fieldAttrs.values"
        :key="key"
        v-model="value">
        <ion-label>
          {{ checkbox.text }}
        </ion-label>
        <ion-checkbox
          v-model="value"
          :value="checkbox.value" />
      </ion-item>
    </ion-list>

   <ion-item :class="classes" v-else-if="fieldAttrs.type === 'date'">
      <FormFieldDate
        @changeField="change"
        :fieldID="fieldID"
        :fieldAttrs="fieldAttrs"
        :name="name"
        :values="values" :class="classes"
        :label="parseLabel()"
      />
  </ion-item>

   <ion-item :class="classes" v-else-if="fieldAttrs.type === 'datetime'">
    <ion-label>
      {{ parseLabel() }}

      <ErrorMessage
        class="error-message-label"
        :name="name" />
    </ion-label>

    <ion-button
      fill="clear"
      :id="fieldID">
      <ion-input
        v-model="value"
        v-bind="fieldAttrs"
      />
      <ion-icon :icon="calendarIcon" />
    </ion-button>
    <ion-popover :trigger="fieldID" :show-backdrop="false">
      <ion-datetime
        @click="(v) => value = moment({
          ...v.target.activePartsClone,
          month: v.target.activePartsClone.month - 1,
        }).format(fieldAttrs.format || 'YYYY-MM-DD HH:mm:ss')"
        :locale="locale"
        v-model="value"
        presentation="date-time"
      />
    </ion-popover>
  </ion-item>

   <ion-item :class="classes" v-else-if="fieldAttrs.type === 'time'">
    <ion-label>
      {{ parseLabel() }}

      <ErrorMessage
        class="error-message-label"
        :name="name" />
    </ion-label>

    <ion-button
      fill="clear"
      :id="fieldID">
      <ion-input
        v-model="value"
        v-bind="fieldAttrs"
      />
      <ion-icon :icon="calendarIcon" />
    </ion-button>
    <ion-popover :trigger="fieldID" :show-backdrop="false">
      <ion-datetime
        @click="(v) => value = moment({
          ...v.target.activePartsClone,
          month: v.target.activePartsClone.month - 1,
        }).format(fieldAttrs.format || 'HH:mm')"
        :locale="locale"
        v-model="value"
        presentation="time"
      />
    </ion-popover>
    </ion-item>

   <ion-item :class="classes" v-else-if="fieldAttrs.type === 'autocomplete'" class="autocomplete"
    v-show="!fields[name]?.hidden"
   >
    <FormFieldAutocomplete
      @changeField="change"
      :fieldAttrs="fieldAttrs"
      :name="name"
      :values="values"
      :label="parseLabel()"
    />
  </ion-item>

    <ion-button v-else-if="fieldAttrs.type === 'submit'"
      v-bind="fieldAttrs"
    >{{ parseLabel() }}</ion-button>

    <ion-item :class="classes" v-else 
    v-show="!fields[name].hidden"
    v-bind="fields[name].itemAttrs"
    >
      <ion-label
        position="floating">
        {{ parseLabel() }}

        <ErrorMessage
          class="error-message-label"
          :name="name" />
      </ion-label>
       <!-- @click.stop="" -->
      <ion-input 
        v-model="value"
        v-bind="fieldAttrs"
      />
    </ion-item>
  <!-- </ion-col> -->
  </Field>
</template>

<script>
import uniqid from 'uniqid';
import moment from 'moment';
import { Field, ErrorMessage } from 'vee-validate';
import { //IonCol,
  IonInput, IonCheckbox, IonItem, IonLabel, IonListHeader,
  IonList, IonRadio, IonRadioGroup, IonSelect, IonSelectOption,
  IonPopover, IonIcon, IonButton, IonDatetime
} from '@ionic/vue';
import { calendarOutline as calendarIcon } from 'ionicons/icons';
import { mapState } from 'vuex';
import FormFieldAutocomplete from './fields/FormFieldAutocomplete.vue';
import FormFieldDate from './fields/FormFieldDate.vue';


export default {
  components: {
    Field,
    ErrorMessage,
    //IonCol,
    IonInput, IonCheckbox, IonItem, IonLabel, IonListHeader,
    IonList, IonRadio, IonRadioGroup, IonSelect, IonSelectOption, IonPopover, IonIcon, IonButton, IonDatetime, FormFieldAutocomplete,
    FormFieldDate,
  },
  emits: ['changeField'],
  props: {
    name: {
      type: String,
      required: true,
    },
    fields: {
      type: Object,
      required: true,
    },
    values: {
      type: Object,
      required: true,
    },
    fieldAttrs: {
      type: Object,
      default: null,
    },
    classes:String
  },
  data() {
    return {
      calendarIcon,
      fieldID: uniqid(),
      moment,
    };
  },
  computed: {
    value: {
      get() {
        return this.values[this.name];
      },
      set(value) {
        if( this.values[this.name] == value ) return;

        let newValue = value;

        if (this.fieldAttrs.type === 'number') {
          newValue = isNaN(parseFloat(value))?undefined:Number(value);
        }

        if (typeof this.fieldAttrs.change === 'function') {
          this.fieldAttrs.change(newValue);
        }

        this.change(newValue);

        return newValue;
      }
    },
    ...mapState(['locale']),
  },
  methods: {
    change(value, options) {
      this.$emit('changeField', this.name, value, options);
    },
    parseLabel() {
      if (this.fieldAttrs && this.fieldAttrs.noLabels) {
        return null;
      }

      return this.$t(`fields.${this.name}`);
    },
  },
};
</script>

<style scoped>
  .error-message-label {
    color: var(--ion-color-danger);
    margin: 1em;
  }
  /* ion-item{
    --min-height: auto;
    width:auto
  } */
  .input-row {
    /* --padding-bottom: 0px !important; */
    /* --padding-top: 0px !important; */
    --padding-start: 0px !important;
    --padding-end: 0px !important;
  }
  .input-row-monitor{
    width:45px;
    --min-height:30px
  }  

  /* Hide Calendar Icon In Chrome */
  ion-input[type=date]::-webkit-inner-spin-button,
  ion-input[type=date]::-webkit-calendar-picker-indicator {
      display: none;
      -webkit-appearance: none;
  }

.hiddeIonLabel ion-label{
  display: none;
}
.hiddeIonLabel {
--min-height:0
}
ion-radio-group ion-label{
  padding-right: 7px;
}

/* Chrome, Safari, Edge, Opera */
.disable-arrows input[type=number]::-webkit-outer-spin-button,
.disable-arrows input[type=number]::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
}

/* Firefox */
.disable-arrows input[type=number] {
  -moz-appearance: textfield;
}
</style>