import axios from 'axios';
import TokenService from '@/services/oauth';
import store from '../store';

class API {
    constructor() {
        this.init();

        this.mountRequestInterceptor();
        this.mount401Interceptor();
    }

    init() {
        axios.defaults.baseURL = process.env.VUE_APP_MULTI_API_IP;

        this._401interceptor = 0;
    }

    async setHeader() {
        const token = await TokenService.getBearerToken();

        if (token) {
            //new 
            // this.token = token;
            // axios.defaults.headers.common.Authorization = `Bearer ${token}`;
            await store.dispatch('setBearerToken', token);
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }

        return token;
    }

    mountRequestInterceptor() {
        axios.interceptors.request.use(
            async request => {
              if (!store.state.bearerToken) {
                await this.setHeader();
              }
              return {
                ...request,
                headers: {
                  Authorization: `Bearer ${store.state.bearerToken}`,
                }
              };
            },
            error => {
              return Promise.reject(error);
            }
        );
    }

    // new 
    // mount401Interceptor() {
    // axios.interceptors.response.use(
    //     (response) => response,
    //     async (error) => {
    //     if (error.request && error.request.status === 401) {
    //         this.token = null;
    //         await this.setHeader();
    //         return axios.request(error.config);
    //     }
    //     throw error;
    //     },
    //     );
    // }
    mount401Interceptor() {
        axios.interceptors.response.use(
            response => {
                return response;
            },
            async error => {
                console.log(error);

                if (error.request.status === 401) {
                    await store.dispatch('resetBearerToken', null);
                    await this.setHeader();
                    return axios.request(error.config);
                }
                else {
                    store.commit('setErrorMessage',  error?.request?.responseText || error)
                }
                throw error;
            }
        );
    }

    login(data) {
        return axios.get('1', { params: data }).then(({ data }) => { 
            if((data?.length||0)==0) throw new Error('Wrong Username/Password');
            if(!data[0].IsAdmin) throw new Error('Internet Admin users can login!');

            return data
        });
    }

    getUserPrivileges(data){
        return axios.get('60', {params:data}).then(({data}) => data)
    }

    fetchReservationHistory(data){
        return axios.get('88', {params:data}).then(({data}) => data)
    }

    fetchTicketHistory(data){
        return axios.get('90', {params:data}).then(({data}) => data)
    }

    fetchUserHistory(data){
        return axios.get('96', {params:data}).then(({data}) => data)
    }

    createUserHistoryRecord(data){
        return axios.post('97', data).then(({data}) => data)
    }

    getExcursions(data) {
        return axios.get('9', {
            params: {...data, B2B: 1},
        }).then(({ data }) => data);
    }

    getHotels(data) {
        return axios.get('40', data).then(({ data }) => data);
    }

    // getExcursions(data) {
    //     return axios.get('22', {
    //         params: data,
    //     }).then(({ data }) => data);
    // }

    // getExcursion(data) {
    //     return axios.get('10', { params: data }).then(({ data }) => data);
    // }

    getMonitorData(data){//'56'
        return axios.get('9', { params: {...data, ...{B2B:1, HISTORY: 1} } }).then(({ data }) => data?.data?.filter(x=> x.Dates.length>0) || [] );
    }

    // _excdate(payload){
    //     // const exc = data.EXCURS; delete data.EXCURS;
    //     // const dt = data.DATE; delete data.DATE;
    //     return axios.put('36', payload).then(({ data }) => data);
    // }

    stopSale(payload){
        return axios.put('36', payload).then(({ data }) => data);
    }

    addPax(payload){
        return axios.put('36', payload).then((data) => data);
    }

    getReservationPickups(data){
        return axios.get('57',  { params: data }).then(({data}) => data);
    }

    getGuides(data){
        return axios.get('64', { params: data }).then(({ data }) => data);
    }
    
    getDrivers(){
        return axios.get('59').then(({ data }) => data);
    }
    getExcdateBus(data){
        return axios.get('22', { params: data }).then((data) => data);
    }
    getDriverActivity(data){
        return axios.get('85', { params: data }).then(({ data }) => data);
    }
    getGuideActivity(data){
        return axios.get('86', { params: data }).then(({ data }) => data);
    }
    updateExcdateBus(payload){//console.log('payload ',payload);
        return axios.put('63', payload).then((data) => data);
    }
    removeExcdateBus(data){ console.log('removeExcdateBus');
        // return axios({
        // method: 'DELETE',
        // url: '66',
        // data: {
        //   ...payload
        // }}).then((data) => data)
        return axios.delete('66', {data:data}).then((data) => data);
    }
    getExcdateBusPickup(data){
        return axios.get('65', { params: data }).then((data) => data);
    }
    updateExcdateBusPickup(payload){ //console.log('updateExcdateBusPickup', payload);
        return axios.put('67', payload).then((data) => data);
    }
    deleteExcdateBusPickup(data){
        return axios.delete('78', {data:data}).then((data) => data);
    }

    cash(data){ console.log(data);
        return axios.get('76', { params: data }).then((data) => data);
    }
    updateCash(payload){
        return axios.put('74', payload).then((data) => data);
    }
    removeCash(data){
        return axios.delete('75', {data:data}).then((data) => data);
    }

    excursionPickuptimeException(data){
        return axios.get('77', { params: data }).then((data) => data);
    }
    updateExcursionPickuptimeException(payload){
        return axios.put('70', payload).then((data) => data);
    }
    removeExcursionPickuptimeException(data){
        return axios.delete('71', {data:data}).then((data) => data);
    }

    getSalespeopleExcursionPickup(data){
        return axios.get('73', { params: data }).then((data) => data);
    }
    updateSalespeopleExcursionPickup(payload){
        return axios.put('68', payload).then((data) => data);
    }
    removeSalespeopleExcursionPickup(data){
        return axios.delete('69', {data:data}).then((data) => data);
    }
    getSalespeopleExcursionPrice(data){
        return axios.get('91', { params: data }).then((data) => data);
    }
    updateSalespeopleExcursionPrice(payload){
        return axios.put('92', payload).then((data) => data);
    }
    removeSalespeopleExcursionPrice(data){
        return axios.delete('93', {data:data}).then((data) => data);
    }
    getSalespeople(data){ //'35'
        return axios.get('6', { params: data }).then(( data ) => data);
    }
    getUsers(data){
        return axios.get('89', { params: data }).then(( data ) => data);
    }

    getTouroperator(data){
        return axios.get('19', { params: data }).then(( data ) => data);
    }

    getAdvancedTicketReport(data) {
        return axios.get('82', { params: data }).then(({data}) => data);
    }

    getTicketEarnings(data) {
        return axios.get('83', { params: data }).then(({data}) => data);
    }

    getCashReport(data) {
        return axios.get('84', { params: data }).then(({data}) => data);
    }

    getB2CSalesReport(data) {
        return axios.get('94', { params: data }).then(({data}) => data);
    }

    getPickups(data){
        return axios.get('9', { params: {...data, ...{B2B:1, HISTORY: 1} } }).then(( data ) => data);
    }

    getTicketTypes(){
        return axios.get('25').then(( data ) => data);
    }

    getCurrencies(){
        return axios.get('8').then(( data ) => data);
    }

    getDefaultCurrency(params){
        return axios.get('54', {params}).then(( data ) => data);
    }

    getExcateresa(data){
        return axios.get('11', { params: data }).then(( data ) => data);
    }
    updateExcateresa(payload){
        return axios.put('62', payload).then((data) => data);
    }

    cancelExcateresa(data){
        return axios.delete('33', { 
            data:{
                ...data, 
                ...{ USERCRE: store.state.user.UserId }
            }
        }).then((data) => data);
    }

    undoCancelExcateresa(payload){
        return axios.put('87', payload).then((data) => data);
    }

    deleteExcateresa(data){ // 80 ?
        return axios.delete('80', {data:data}).then((data) => data);
    }

    nextTicket(data){
        return axios.get('26', { params: data }).then(( data ) => data);
    }

    getTickets(data){
        return axios.get('30', { params: data }).then(( data ) => data);
    }

    getDriversInExcurs(params) {
        return axios.get('81', {
          params,
        }).then(({ data }) => data);
    }
    

    // removeExcursionPickupTimeException(data){
    //     return axios.delete('71', {data:data}).then((data) => data);
    // }
    // getGeneralParameters() {
    //     return axios.get('61').then(({ data }) => data);
    // }

    



    // getTransfers(data) {
    //     return axios.get('48', {
    //         params: data,
    //     }).then(({ data }) => data);
    // }

    getReservations(data) {
        return axios.get('18', {
            params: data,
        }).then(({ data }) => data);
    }

    // setInBus(data) {
    //     return axios.post('23', {
    //         params: data,
    //     }).then(({ data }) => data);
    // }

    // removeFromBus(data) {
    //     return axios.delete('24', {
    //         params: data,
    //     }).then(({ data }) => data);
    // }
    getExcursion(params) {
        return axios.get('10', {
          params,
        }).then(({ data }) => data && data[0]);
    }
    getReservation(params) {
        return axios.get('11', {
          params,
        }).then(({ data }) => data);
      }
      fetchWaypays(params) {
        return axios.get('28', {
          params,
        }).then(({ data }) => data);
      }
      fetchPickupExceptions(params) {
        return axios.get('73', {
          params,
        }).then(({ data }) => data);
      }
      bookReservation(params) {
        return axios.post('13', params).then(({ data }) => data);
      }
      fetchPrice(params) {
        return axios.get('12', {
          params,
        }).then(({ data }) => data);
      }
}

export default new API();